/* custom fonts */

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}


/* default css */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

:root{
  --main-background: #212121;
  --background-linear: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}

body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #212121 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}





/************ Navbar Css ************/
header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0 1rem;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  background-color: transparent;
}

header h3{
  margin-left: 5rem;
}

nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.scrolled {
  /* padding: 0px 0; */
  background-color: var(--main-background);
}

.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 2px 20px;
  font-size: 12px;
  margin-right: 1rem;
  opacity: 0.75;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.navbar-link::before{
  content: "";
  border-radius: 5px;
  width: 100%;
  height: 0%;
  position: absolute;
  background: var(--background-linear);  
  left: 0;
  bottom: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-link:hover,
.navbar-link.active {
  opacity: 1;
}

.navbar-link:hover::before,
.navbar-link.active::before{
  height: 100%;
  content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
/* .nav-img2{
  border-radius: 50%;
  width: 50% !important;
} */
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  border-radius: 10px;
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

.nav-btn{
  padding: 5px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  outline: none;
}

.icon{
  height: 25px;
  width: 25px;
}


@media only screen and (max-width: 1024px){
  .nav-btn{
    visibility: visible;
    opacity: 1;
  }

  header h3{
    margin-left: 0;
  }

  header nav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
    transition: 1s;
    transform: translateY(-100vh);
    background-color: var(--main-background);
  }

  .link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;
    margin-top: 30%;
  }

  .navbar-text{
    flex-direction: column;
    margin-bottom: auto;
  }
  .vvd{
    margin-top: 3rem;
  }

  .responsive-nav{
    transform: none;
  }

  .nav-close-btn{
    position: absolute;
    right: 2rem;
    top: 1rem;
  }
  .responsive{
    display: none;
  }

  .project .nav.nav-pills {
    width: 95% !important;
  }

  footer h3{
    text-align: center;
    margin-top: 4rem;
  }
}





/************ banner ************/
.banner {
  margin-top: -5rem;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: var(--background-linear); 
  border-radius: 10px;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 1rem;
  height: fit-content !important;
  width: 100% !important;
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
      transform: translateY(-20px);
  }
  50% {
      transform: translateY(20px);
  }
  100% {
      transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
border-right: 0.08em solid #666;
}

@media only screen and (max-width: 1024px){
  .banner h1 {
    height: 90px;
    font-size: 40px;
    width: 360px;
    margin-top: 1rem;
  }

  .headerImg{
    margin-top: 4rem;
  }
}


/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  /* border: 1px solid #4A2FBD; */
  box-shadow: #4A2FBD 0px 5px 10px -20px, #AA367C 0px 3px 2px -30px, #a85e8b 0px -2px 6px 0px inset;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
  margin: 4rem 0;
}
.skill-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 80px;
  height: 80px;
  margin: 0 auto 15px auto;
}

.skill-slider .item{
  text-transform: uppercase;
}

.background-image-left {
  top: 0;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -1;

}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  margin-bottom: 4rem;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}


.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
  border: 1px solid rgb(53, 53, 53);
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
  border: 1px solid rgb(53, 53, 53);
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}


/* .project .nav-item .active {
} */

.project .nav-link.active::before {
  width: 100% !important;
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 220px;
  width: 95%;
  margin: 10px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.proj-txtx span a{
  font-size: 30px;
  color: #fff;
}

.proj-txtx span a:hover{
  color: #B8B8B8;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}


/************ Contact Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}

.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  margin-left: 1rem;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  margin: auto;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  border-radius: 10px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

.contact form .success{
  background-color: rgb(48, 168, 48);
  color: #FFFFFF;
  width: 200px;
  margin: 2rem 0;
  padding: 5px 0;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}
.unsuccessfull{
  background-color: rgb(168, 48, 48);
  color: #FFFFFF;
  width: 400px;
  margin: 2rem 0;
  padding: 5px 0;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}


/* .newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
} */


.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}






.tab{
  border: 1px solid red;
  height: 300px;
}

.tab ul{
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab ul li{
  display: inline;
  color: white;
  text-align: center;
  background-color: #4A2FBD;
  padding: 15px;
}

.tab ul li:hover{
  color: green;
  cursor: pointer;
  background-color: white;
}

.content{
  display: none;
}

.show-content{
  display: block;
}